.page-dashboard {
  margin-top: -15px;

  .header-title {
    padding: 15px 60px;
    color: @color-grey-light;
    background: white;
    box-shadow: 0 2px 4px 0 @color-grey-light;

    h2 {
      margin-top: 0;
    }
  }
  h3 {
    color: @color-grey-light;
    &::after {
      display: block;
      width: 15px;
      content: "";
      border-bottom: 2px solid @color-grey-light;
    }
  }
  .region-dashboard-session-list {
    padding-left: 15px;
    border-left: 1px solid @color-grey-medium;
  }
  .session-list {
    display: block;
    padding-left: 15px;

    .list-group {
      margin: 0 0 0 -5px;
    }
    .session-box {
      display: flex;
      margin: 15px 0;
      width: 100%;
      justify-content: flex-start;
      align-items: center;

      .session-link {
        width: 100%;
      }
      .session-print {
        margin: 0 15px;

        i {
          color: transparent;
          font-size: 40px;
        }
      }
      &:hover .session-print i {
        color: @color-grey-medium;
      }
    }
  }
}
