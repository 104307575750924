@import "vars";

.fa-spinner,
.fa-pulse {
    -webkit-transform-origin: 51% 52%;
    -moz-transform-origin: 50% 52.5%;
}

.visible-ajax {
    display: none;

    .ajax & {
        display: initial;
    }
}

.hidden-ajax {
    display: initial;

    .ajax & {
        display: none;
    }
}

.media-left,
.media-right {
    white-space: nowrap;
}

.btn,
.btn-group > .btn:first-child:not(:last-child),
.btn-group > .btn:last-child:not(:first-child),
.btn-group-vertical > .btn:first-child:not(:last-child),
.btn-group-vertical > .btn:last-child:not(:first-child) {
    border-radius: 0;
}

p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    header {
        position: fixed;
        z-index: 1;
        top: 0;
        width: 100%;
    }

    main {
        flex-grow: 1;
        position: relative;
    }
}

header {
    text-align: center;

    .header-main,
    .header-info {
        background: @header-background-color;
        color: white;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .header-info {
        background: fade(@brand-info, 80%);
        font-size: 1.2em;
    }

    .brand-logo {
      float: left;
      background-repeat: no-repeat;
      background-size: contain;
    }
}

@media (min-width: @screen-sm-min) {
    header {
        text-align: left;

        .header-main,
        .header-info {
            padding-left: @grid-gutter-width;
        }

        .header-info {
            text-align: center;
        }
    }

    h2 {
      font-size: 20px;
    }
}

@media (max-width: @screen-sm-min) {
    h2 {
        font-size: 18px;
        float: left;
        margin: 0;
        position: relative;
        top: 1.5px;
    }

    header {
      .header-main {
        padding-left: 10px;
        padding-top: 10px;

        ul.list-inline {
            text-align: right;
            padding-right: 10px;
        }
      }

      .header-info {
        font-size: 1em;
      }
    }
}

.vmap-container {
    position: absolute;

    // full height
    top: 0;
    bottom: 0;

    // full width
    left: 0;
    right: 0;

    .vmap {
        height: 100%;
    }
}

.jvectormap-tip {
    border: 0;
    border-radius: 0;
    background: fade(@gray-dark, 70%);
}

.vmap-controls {
    position: fixed;
    bottom: (@grid-gutter-width * 1.7);
    right: (@grid-gutter-width / 4);

    display: flex;
    flex-direction: column;

    .btn-group-vertical {
        margin-top: (@grid-gutter-width / 4);
    }
}

.layout-map {
    background: white;
    text-align: center;
    padding: 1em;

    svg {
        max-width: 100%;
        max-height: ~"calc(100vh - 350px)";
        border: 3px dashed @color-grey-light;
    }
}

a,
input,
button {
  cursor: pointer;
}
.table-row {
  display: table-row;
  margin: 0;
  padding: 0;
  width: 100%;
}
.table-row > .table-cell {
  display: table-cell;
  margin: 0;
  padding: 0 5px;
  vertical-align: middle;
  white-space: nowrap;
}

.navbar,
.navbar-default {
  height: 80px;
  margin-bottom: 0;
  color: @color-main-back;
  background: @color-main-alt;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  z-index: 10;

  .navbar-header {
    height: 80px;
  }

  .navbar-brand,
  .navbar-brand:focus,
  .navbar-brand:hover {
    padding: 27px 0 0 0;
    color: @color-nav-link-active;
    font-size: 22px;

    img {
      margin: -7px 41px -10px 46px;
      width: 25px;
      height: 40px;
    }
    .fa {
      margin: 0 37px 0 46px;
      padding: 3px 0;
    }
    .separator {
      margin: 0 41px 0 0;
      padding: 0 0 0 0;
      border-right: 1px solid @color-nav-link-active;
    }
    .title {
      margin: 0;
      padding: 0;
    }
  }
  .navbar-nav li {
    padding-right: 45px;
    font-size: 16px;
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      padding-right: 15px;
    }

    a, a:focus, a:hover {
      padding: 28px 0 30px 0;
      color: @color-nav-link-active;
      font-weight: bold;
      border-bottom: 2px solid @color-main-alt;
    }
  }

  .navbar-nav li.active {
    a, a:focus, a:hover {
      color: @color-nav-link-active;
      background: transparent;
      border-bottom: 2px solid @color-main-back;
    }
  }
}
@media (max-width: @screen-xs-max) {
  .navbar,
  .navbar-default {
    .navbar-toggle {
      margin-top: 23px;
      margin-right: 23px;
      border-color: white;
      .icon-bar {
        background-color: white;
      }
      &:hover, &:focus, &.toggle {
        background-color: white;
        border-color: white;
        .icon-bar {
          background-color: @color-main-alt;
        }
      }
    }
    .navbar-nav {
      margin-top: 0;
      background: @color-main-alt;
    }
    .navbar-nav li {
      padding: 15px;
      a, a:focus, a:hover {
        padding: 0;
      }
    }
  }
}

.sidebar {
  position: relative;
  padding-top: 15px;
  height: ~"calc(100vh - 80px)"; /* Viewport height - Navbar height */
  box-shadow: 0 0 5px 0 #666666;
  z-index: 1;

  h2 {
    padding: 0 15px 15px 15px;
    margin: 0 15px 15px 15px;
    color: @color-grey-light;
    text-align: center;
    border-bottom: 1px solid @color-grey-light;
  }

  .sidebar-back {
    display: table;
    margin: 0;
    padding: 15px;
    width: 100%;
    color: @color-main-alt;
    font-size: 1.3em;
    font-weight: bold;
    box-shadow: 0 2px 2px 0 @color-grey-light;

    a {
      color: @color-main-alt;
    }
    & > span {
      display: table-cell;
      vertical-align: middle;
      &:first-child {
        padding-right: 10px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
    .fa {
      font-size: 1.5em;
    }
  }
  .sidebar-scrollable {
    position: relative;
    margin: 0;
    height: 100%;
    overflow-y: auto;
  }
  .region-sidebar-heading {
    margin: 0;
  }
  .region-sidebar-tool {
    margin: 0;
    padding-top: 0;
    padding-bottom: 45px; /* Action button height */
    height: ~"calc(100vh - 80px)"; /* Viewport height - Navbar height */
    overflow-y: auto;
  }
  /* .sidebar-actions */
  .sidebar-menu {
    .menu-group {
      margin: 0 15px 15px 15px;
      padding: 15px 0 0 0;
      color: @color-main-alt;
      border-bottom: 1px solid @color-main-alt;
    }
    .menu-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    .menu-list li {
      display: block;
      padding: 10px 15px;
      background: @color-list-even;
      color: @color-grey-light;
      font-size: 1.5em;

      a {
        color: @color-grey-light;
      }
      &:nth-child(odd) {
        background: @color-list-odd;
      }
      .id, .id a {
        color: @color-main-alt;
        font-weight: bold;
      }
      .action {
        color: @color-main-alt;
      }
      &.active {
        color: @color-main-back;
        background: @color-main-alt;

        a {
          color: @color-main-back;
        }
        .id,
        .id a,
        .action,
        .fa.disabled {
          color: @color-main-back;
        }
      }
      .fa.disabled {
        color: @color-grey-light;
      }
    }
  }

  .sidebar-list ul {
    padding-left: 0;
    list-style-type: none;
    list-style-position: outside;
  }

  .sidebar-list li {
    a {
      display: block;
      padding: 10px 15px;
      background: @color-list-even;
      text-decoration: none;
    }

    &:nth-child(odd) a {
      background: @color-list-odd;
    }

    &.active a {
      color: @color-main-back;
      background: @color-main-alt;
    }
  }

  .sidebar-actions {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 15px;
    width: 100%;
    background: @color-main-back;

    button {
      padding: 10px 15px;
      width: 100%;
      color: @color-main-alt;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      background: transparent;
      border: 0;
      border-top: 1px solid @color-grey-light;
    }
  }
}

.content {
  position: relative;
  padding-top: 15px;
  height: ~"calc(100vh - 80px)"; /* Viewport height - Navbar height */
  background: lighten(@color-grey-light, 20%);
  overflow: auto;

  .title h2 {
    color: @color-grey-medium;
  }
  .title-bar {
    margin: -15px -15px 15px -15px;
    padding: 10px 10px 10px 95px;
    color: white;
    background: @color-grey-medium;

    a {
      color: white;
    }
    h2.inline {
      display: inline;
    }
    input.form-control {
      display: block;
      float: left;
      margin: 0;
      padding: 0;
      width: 300px;
      color: white;
      font-size: 24px;
      background: @color-grey-medium;
      border: 1px solid @color-grey-medium;
      box-shadow: none;
      &:focus {
        margin-right: 10px;
        margin-left: -10px;
        padding: 0 10px;
        background: lighten(@color-grey-medium, 10%);
        border: 1px solid lighten(@color-grey-medium, 30%);
      }
    }
    .edit {
      display: block;
      float: left;
      margin-left: 20px;
      padding-top: 9px;
      color: darken(white, 20%);
      font-size: 16px;
    }
  }
  .title-bar-light {
    margin: 0;
    padding: 15px 15px 15px 45px;
    color: @color-grey-medium;
    background: lighten(@color-grey-light, 20%);

    a {
      color: @color-grey-medium;
    }
    h2.inline {
      display: inline;
    }
    .title-input::after {
      display: block;
      clear: both;
      width: 15px;
      margin-bottom: 10px;
      content: "";
      border-bottom: 2px solid @color-grey-medium;
    }
    input.form-control {
      display: block;
      float: left;
      margin: 0;
      padding: 0;
      width: 300px;
      color: @color-grey-medium;
      font-size: 24px;
      background: lighten(@color-grey-light, 20%);
      border: 1px solid lighten(@color-grey-light, 20%);
      box-shadow: none;
      &:focus {
        margin-right: 10px;
        margin-left: -10px;
        padding: 0 10px;
        background: @color-grey-light;
        border: 1px solid darken(@color-grey-light, 20%);
      }
    }
    .edit {
      display: block;
      float: left;
      margin-left: 20px;
      padding-top: 9px;
      color: lighten(@color-grey-medium, 20%);
      font-size: 16px;
    }
    .title-select {
      color: @color-grey-medium;
      font-size: 20px;
      select {
        font-size: 18px;
        background: lighten(@color-grey-light, 20%);
        border: 1px solid lighten(@color-grey-light, 20%);
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: "";
      }
      option {
        background: lighten(@color-grey-light, 20%);
        border: 1px solid darken(@color-grey-light, 20%);
        box-shadow: none;
      }
      option:hover,
      option:checked {
        background: @color-grey-light;
      }
      &::after {
        display: inline-block;
        margin-left: -10px;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 16px;
        content: "\f078";
      }
    }
  }
  .title-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    color: white;
    text-align: center;
    font-weight: bold;
    background: lighten(@color-main-alt, 10%);
  }
}

.modal {
  text-align: center;
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    color: @color-main-alt;
    text-align: center;
    font-size: 1.2em;
  }
  .modal-footer {
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%;
    border: 0;

    .action-list {
      margin: 0;
      padding: 0;
      width: 100%;
    }
    .btn {
      margin: 0;
      padding: 7px 0;
      border: 0;
    }
    .btn-primary {
      background: @color-main-alt;
    }
    .btn-danger {
      background: @color-cto-error;
    }
  }
}
.modal-backdrop {
  &.in {
    opacity: 0.8;
  }
}
@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: white;
}

/* ************************* */
/* Bootstrap Toggle Override */
/* Require: FontAwesome      */
/* ************************* */
.btn.toggle {
  background: transparent;
  color: @color-main-alt;
  border: 2px solid @color-main-alt;
  border-radius: 20px;

  .toggle-group {
    left: -15px;

    .btn.toggle-on, .btn.toggle-off {
      font-weight: bold;
      background: transparent;
      border: 0;
      box-shadow: none;
    }
    .btn.toggle-on {
      color: @color-main-alt;
    }
    .btn.toggle-off {
      color: @color-grey-light;
    }
    .btn.toggle-handle {
      margin: -2px;
      width: 34px;
      height: ~"calc(100% + 4px)";
      color: @color-main-alt;
      border: 2px solid @color-main-alt;
      border-radius: 50px;

      &::after {
        display: inline-block;
        margin-top: 5px;
        margin-left: -7px;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 20px;
        content: "\f00c";
      }
    }
  }
  &.off {
    color: @color-main-alt;
    border: 2px solid @color-grey-light;

    .toggle-group {
      left: ~"calc(-100% + 15px)";

      .btn.toggle-handle {
        color: @color-grey-light;
        border: 2px solid @color-grey-light;

        &::after {
          margin-left: -6px;
          content: "\f00d";
        }
      }
    }
  }
}
/* ****************************** */

/* Filter bar */
.filter-group {
  display: table-row;

  .filter {
    display: table-cell;
    padding: 0 5px 0 0;
    vertical-align: middle;
  }
}

/* Session tile */
.session-list {
  margin: 15px -15px;

  a:hover {
    text-decoration: none;
  }
}

.session-list:empty {
    &::before {
        content: "Aucun résultat.";
    }
}

.session-tile {
  margin: 5px 0;
  padding: 7px;
  background: white;
  box-shadow: 2px 2px 4px 0 @color-grey-light;
  &, & a {
    color: @color-grey-medium;
  }
  &:hover {
    background: lighten(@color-grey-light, 15%);
  }

  .tile-action {
    display: table;
    width: 100%;

    & > div {
      display: table-cell;
      text-align: center;

      &:first-child {
        padding-right: 7px;
        text-align: left;
      }
      &:last-child {
        padding-left: 7px;
        text-align: right;
      }
    }
  }
}

/* ******************* */
/*     Glyphs Font     */
/* ******************* */
.icon-link,
.icon-link:hover {
  text-decoration: none;
}

.btn-info {
  background-color: @btn-info-bg;
  border-color: @btn-info-border;

  &:hover,
  &:focus,
  &.active,
  &.active.focus,
  &.active:focus,
  &.active:hover,
  &:active.focus,
  &:active:focus,
  &:active:hover,
  .open>.dropdown-toggle&.focus,
  .open>.dropdown-toggle&:focus,
  .open>.dropdown-toggle&:hover {
    background-color: @btn-info-bg-active;
    border-color: @btn-info-border-active;
    outline: none;
  }
}


.legend {

  .initial {
    color: @initial-fill;
    border-color: @initial-stroke;
  }

  .selected {
    color: @selected-fill;
    border-color: @selected-stroke;
  }

  .regions {

    .bike-active {
      color: @bike-active-fill;
      border-color: @bike-active-stroke;
    }

    .bike-available {
      color: @bike-available-fill;
      border-color: @bike-available-stroke;
    }

    .bike-coach {
      color: @bike-coach-fill;
      border-color: @bike-coach-stroke;
    }

    .bike-unavailable {
      color: @bike-unavailable-fill;
      border-color: @bike-unavailable-stroke;
    }
  }
}

footer {
  position: fixed;
  bottom: 0;
}
