@import "../app/vars";

.content > .page-session {

  .session-list {
    display: block;
    padding-left: 15px;
  }

  .vmap-controls {
    bottom: 50px;
  }

  .content-actions-fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .cta {
      padding: 7px 20px;
      color: white;
      font-weight: normal;
      font-size: 18px;
      background: @color-main-alt;
      border: 0;
    }
    .cta-normal {
      background: @color-main-alt;
    }
    .cta-valid {
      background: @color-cto-valid;
    }
    .cta-delete {
      background: @color-cto-error;
    }

  }
}

.sidebar {
  .session-box {
    margin-top: -15px;
    padding: 15px;
    color: white;
    background: @color-grey-light;
  }
  .session-title {
    margin-bottom: 7px;
    padding-bottom: 7px;
    text-align: center;
    font-size: 1.6em;
    border-bottom: 1px solid white;
  }
  .session-informations {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    .table-row {
      display: block;
      text-align: center;
    }
    .table-cell {
      display: inline;
      padding: 0 5px;
      width: inherit;
      vertical-align: middle;
      white-space: nowrap;
    }
  }
  .table-cell:first-child {
    text-align: left;
  }
  .table-cell:last-child {
    text-align: right;
  }
  .session-footer {
    padding-top: 7px;
    text-align: center;
  }

  .session-menu-mini {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    li {
      margin: 15px 0;
      padding: 0;
      text-align: center;
    }
    li a {
      color: @color-grey-dark;
    }
  }

  .sidebar-actions {
    background: @color-grey-light;

    button {
      color: white;
      border: 0;
    }
  }
}

.page-session {
  h3 {
    color: @color-grey-light;
    &::after {
      display: block;
      width: 15px;
      content: "";
      border-bottom: 2px solid @color-grey-light;
    }
  }
}

i.help-header {
  position: relative;
  top: 1px;
  margin-left: 3px;
}

.help-text {
  display: none;
}

@media(max-width: 480px) {
  .help-text {
    font-size: 13px;
  }
}
