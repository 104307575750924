@import "../app/vars";

.region-template-list {
  padding-top: 25px;

  .sidebar-list {
    li a {
      padding: 17px 15px;
      color: @color-grey-medium;
      font-size: 19px;

      .small {
        font-size: 0.9em;
        color: lighten(@color-grey-medium, 10%);
      }
    }
    li.active a {
      color: white;

      .small {
        color: white;
      }
    }
  }
}

.content > .page-template {

  .template-map {
    padding: 15px;
    text-align: center;
    background: white;
  }

  .content-actions {
    padding: 15px;
    text-align: center;

    .cto {
      margin: 15px 30px;
      padding: 7px 20px;
      font-weight: normal;
      font-size: 18px;
      background: @color-main-back;
      border: 0 solid @color-grey-light;
      box-shadow: 1px 1px 2px 0 @color-grey-light;
    }
    .cto-normal {
      color: @color-main-alt;
    }
    .cto-valid {
      color: @color-cto-valid;
    }
    .cto-delete {
      color: @color-cto-error;
    }

  }
}

.sidebar {
  .template-box {
    margin-top: -15px;
    padding: 15px;
    color: white;
    background: @color-grey-light;
  }
  .template-title {
    margin-bottom: 7px;
    padding-bottom: 7px;
    text-align: center;
    font-size: 1.6em;
    border-bottom: 1px solid white;
  }
  .template-informations {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    .table-row {
      display: block;
      text-align: center;
    }
    .table-cell {
      display: inline;
      padding: 0 5px;
      width: inherit;
      vertical-align: middle;
      white-space: nowrap;
    }
  }
  .table-cell:first-child {
    text-align: left;
  }
  .table-cell:last-child {
    text-align: right;
  }

  .template-menu-mini {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    li {
      margin: 15px 0;
      padding: 0;
      text-align: center;
    }
    li a {
      color: @color-grey-dark;
    }
  }

  .sidebar-actions {
    background: @color-grey-light;

    button {
      color: white;
      border: 0;
    }
  }
}

.page-template {
  h3 {
    color: @color-grey-light;
    &::after {
      display: block;
      width: 15px;
      content: "";
      border-bottom: 2px solid @color-grey-light;
    }
  }
}
