@import "../app/vars";
@import url("https://fonts.googleapis.com/css?family=Ubuntu");

.print {
  footer {
      display: block;
  }

  font-family: "Ubuntu", sans-serif;

  .vmap-container {
    position: relative;
    margin: 10mm 0;
  }

  .booking-list {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 5mm;
    -webkit-column-gap: 5mm;
    column-gap: 5mm;
    -moz-column-rule: 1px solid lightgrey;
    -webkit-column-rule: 1px solid lightgrey;
    column-rule: 1px solid lightgrey;

    ul {
      li:first-child {
        margin-top: 0;
      }
    }

    .booking {
      text-align: right;
      margin: 3mm 0;
      border-bottom: 1px dotted lightgrey;

      h4 {
        display: inline-block;
        top: 0;
        left: 0;
        margin: 0;
        float: left;
        clear: both;
      }
    }
  }

  .hidden-landscape {
    display: block;
  }

  .hidden-portrait {
    display: none;
  }

  @media print and (orientation: landscape) {
    .hidden-portrait {
      display: block;
    }

    .hidden-landscape {
      display: none;
    }

    section:first-child {
      page-break-before: always;
      page-break-after: always;
      height: 100vh;
    }

    .booking-list {
      -moz-column-count: 5;
      -webkit-column-count: 5;
      column-count: 5;
    }

    .vmap-container {
      margin: 0;
      height: 100vh;
      width: 100vw;
    }
  }

  @media print and (orientation: portrait) {
    .vmap-container {
      border: 1px solid lightgrey;
    }
  }
}
